/* Media Queries */

/* 992-1200px */

@media screen and (max-width: 1200px) {
    .container {
        width: 920px;
    }

    .menu,
    .top-info__social {
        margin-left: 100px;
    }

    .top-info__social a {
        margin-right: 10px;
    }

    .top-info__social a:last-child {
        margin-right: 0;
    }
}

/* 762-992px */

@media screen and (max-width: 992px) {
    .container {
        width: 90%;
    }

    .service-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .offer__overhead {
        font-size: 28px;
    }

    .offer__title {
        font-size: 58px;
    }

    .doctor-img {
        width: 366px;
    }

    .top-info {
        flex-direction: column;
        align-items: center;
    }

    .top-info__wrap_social {
        margin-left: 0;
        margin-top: 15px;
    }

    .info-footer__wrap {
        display: flex;
    }

    .info-footer__item_last {
        margin-left: 85px;
    }
}

/* 768-990px */

@media screen and (max-width: 990px) {

    .menu,
    .top-info__social {
        margin-left: auto;
        margin-right: auto;
    }
}

/* 576-768px */

@media screen and (max-width: 768px) {
    .logo {
        display: none
    }

    .menu-open {
        display: block;
    }

    .skill__digit {
        font-size: 41px;
    }

    .doctor-img {
        height: 366px;
        width: auto;
        position: absolute;
        right: 0;
        margin: 0;
    }

    .offer__info {
        padding-top: 31px;
        padding-bottom: 40px;
        z-index: 1;
    }

    .offer__overhead {
        font-size: 35px;
    }

    .offer__subtitle {
        font-size: 22px;
    }

    .offer__btn {
        width: 180px;
        padding: 23px 0;
        font-size: 14px;
    }

    .service-list {
        overflow-x: scroll;
    }


    .service-list::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    .service-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f2ebeb;
    }

    .service-list__item {
        width: 50%;
        margin-bottom: 20px;
    }

    .form {
        margin-top: 30px;
        width: 100%;
    }

    .review {
        flex-direction: column;
        align-items: center;
    }

    .review__profile {
        width: 100%;
    }

    .skill {
        flex-wrap: wrap;
    }

    .form-modal {
        width: 440px;
    }

    .service-list__title span {
        display: block;
    }

    .form-modal__submit {
        width: 300px;
    }

    .medicine-plus {
        display: none;
    }
}

/* 320-576px */

@media screen and (max-width: 576px) {
    .offer {
        height: 366px;
    }

    .offer__subtitle {
        display: none;
    }

    .offer__btn {
        margin-top: 50px;
    }

    .info-footer {
        flex-direction: column;
        padding-left: 30px;
    }

    .info-footer__wrap {
        flex-direction: column;
    }

    .info-footer__item_last {
        margin-left: 0;
    }

    .top-info__map,
    .top-info__email {
        display: none;
    }

    .service-list {
        width: 100%;
    }

    .service-list__item {
        width: 100%;
    }

    .service-card__monday {
        justify-content: flex-start;
    }

    .service-card {
        width: 100%;
    }

    .service-card__monday {
        font-size: 12px;
    }

    .form-modal {
        width: 95%;
    }

    .form-modal__submit {
        width: 100%;
    }

    .review__profile {
        width: 95%;
        margin-bottom: 30px;
    }

    .service-card__days {
        margin-top: 25px;
        width: 100%;
    }

    .service-card {
        height: 456px;
    }

    .menu,
    .top-info__social {
        margin-left: 0;
    }
}