.testimonials {
	background: #effafb;
	font-family: 'Asap', sans-serif;
	padding-top: 80px;
	padding-bottom: 15px;
	position: relative;
}

.medicine-plus {
	position: absolute;
	right: 60px;
	top: 110px;
}

.testimonials-title {
	font-size: 22px;
	font-weight: 600;
	color: #696d33;
	margin-bottom: 8px;
	letter-spacing: 0.025em;
	text-align: center;
}

.testimonials-subtitle {
	font-size: 34px;
	font-weight: 700;
	color: #000;
}

.testimonials-subtitle span {
	color: #00bcd1;
}

.swiper-slide {
	border-style: outset;
	padding: 10px;
}

.review-button {
	color: #fff;
	border: none;
	/* border-radius: 100px; */
	background: #16b0c8;
	display: block;
	width: 300px;
	height: 60px;
	margin: 15px auto 0 auto;
	text-transform: uppercase;
	letter-spacing: 0.088em;
	cursor: pointer;
}

.review-slider__name {
	font-size: 17px;
	font-weight: 600;
	color: #3c3c3c;
	margin-top: 10px;
	text-align: end;
}

.review-slider__name span {
	display: block;
	font-size: 12px;
	font-weight: 300;
	margin-left: 22px;
	margin-top: 5px;
}

.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
	padding-bottom: 11px !important;
}

.swiper-pagination {
	bottom: 0px !important;
}

.form {
	display: flex;
	flex-direction: column;
}

.form-input {
	margin-top: 10px;
	padding: 5px;
	background: #3f9bb1;
	color: white;
	font-size: 15px;
}