/* Specifications */

body {
	padding: 0;
	margin: 0;
	letter-spacing: 0.02em;
	color: #42474c;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
}

div,
p,
form,
input,
a,
span,
button {
	box-sizing: border-box;
}

ul,
li {
	display: block;
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-family: 'PT Serif', serif;
}

h4 {
	font-size: 18px;
	color: #1c374c;
}

a,
a:hover,
a:active {
	text-decoration: none;
}

a,
button,
input {
	transition: all 0.5s ease;
}

input,
input:hover,
input:focus,
input:active,
button,
button:hover,
button:focus,
button:active {
	outline: none;
}

.d-none {
	display: none;
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: space-between;
}

.justify-content-center {
	justify-content: center;
}

.flex-wrap {
	flex-wrap: wrap;
}

.container {
	width: 1200px;
	margin: 0 auto;
}



/* Header */

.top-wrapper {
	background: #1c374c;
}

.top-info {
	padding-top: 28px;
	padding-bottom: 28px;
	color: #fff;
	font-family: 'Roboto', sans-serif;
	justify-content: space-between;
}

.top-info a {
	color: #fff;
}

.top-info__wrap {
	display: flex;
}

.top-info__wrap a {
	width: max-content;
}

.top-info__social {
	margin-left: 430px;
	display: flex;
}

.top-info_social a {
	font-size: 17px;
	color: #25ebff;
	margin-right: 21px;
}

.top-info_social a:hover {
	color: #fff;
}

.top-info_social a:last-child {
	margin-right: 0;
}

.top-info__telephone,
.top-info__email,
.top-info__map {
	margin-right: 30px;
}

.top-info__telephone:hover,
.top-info__email:hover,
.top-info__map:hover {
	color: #25ebff;
}

.top-info__telephone i,
.top-info__email i,
.top-info__map i {
	margin-right: 6px;
}

.logo {
	font-family: 'PT Serif', serif;
	font-size: 23px;
	color: #1c374c;
	font-weight: 600;
}

.navigation {
	padding: 20px 0;
}

.menu {
	align-items: center;
	margin-left: auto;
}

.menu__item {
	margin-right: 20px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
}

.menu__item a {
	color: #1c374c;
}

.menu__item a:hover {
	color: #16b0c8;
	;
}

.menu__item:last-child {
	margin-right: 0;
}

.menu__item_active a {
	color: #16b0c8;
}

.main-header {
	background: rgb(231, 239, 230);
	/* Old browsers */
	background: -moz-linear-gradient(top, rgba(231, 239, 230, 1) 0%, rgba(226, 229, 215, 1) 49%, rgba(218, 226, 208, 1) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(231, 239, 230, 1) 0%, rgba(226, 229, 215, 1) 49%, rgba(218, 226, 208, 1) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(231, 239, 230, 1) 0%, rgba(226, 229, 215, 1) 49%, rgba(218, 226, 208, 1) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e7efe6', endColorstr='#dae2d0', GradientType=0);
	/* IE6-9 */
}

.offer__info {
	padding-top: 90px;
	margin-top: 30px;
}

.offer__overhead {
	font-size: 24px;
	font-family: "PT Serif", Sans-serif;
	font-weight: 700;
	color: #16b0c8;
}

.offer__title {
	font-size: 90px;
	font-family: "PT Serif", Sans-serif;
	color: #16b0c8;
}

.offer__subtitle {
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 32px;
}

.offer__text {
	margin-bottom: 30px;
}

.offer__btn {
	display: block;
	width: 160px;
	background: #fae80c;
	text-align: center;
	padding: 18px 0;
	text-transform: uppercase;
	font-size: 12px;
	border-radius: 5px;
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	border-radius: 40px 40px 40px 40px;
	cursor: pointer;
	border: 2px solid #16b0c8;
	letter-spacing: 0.090em;
}

.offer__btn:active,
.offer__btn:hover {
	-webkit-box-shadow: 0 0 20px 0 #16b0c8 !important;
	box-shadow: 0 0 20px 0 #16b0c8 !important;
}

.doctor-img {
	width: 430px;
	margin-left: auto;
	margin-top: 22px;
}

/* Services */

.service-wrapper {
	flex-direction: column;
}

.services {
	padding-top: 50px;
	padding-bottom: 50px;
	background: url('/public/image/background-info.png') no-repeat top center / cover;
}

.service-list {
	align-items: center;
}

.service-list__item {
	width: 29%;
	text-align: center;
	margin-bottom: 75px;
}

.service-list__item img {
	width: 80px;
	height: 80px;
}

.service-list__title {
	margin-top: 9px;
	margin-bottom: 13px;

}

.service-list__text {
	font-family: 'Roboto', sans-serif;
	font-size: 13px;
	font-weight: 400;
}

/* Form begin*/

.form {
	position: relative;
	background: #16b0c8;
	width: 430px;
	height: 400px;
	outline: 3px solid #55c5d7;
	outline-offset: -13px;
	padding: 30px;
	padding-top: 50px;
	color: #fff;
	/* border-radius: 40px; */
	-webkit-box-shadow: 0 0 20px 5px rgba(22, 176, 200, 0.40);
	box-shadow: 0 0 20px 5px rgba(22, 176, 200, 0.40);
	margin: auto;
}

.form__title {
	text-align: center;
	font-size: 25px;
	font-weight: 500;
	margin-bottom: 20px;
}

.form__textarea {
	border: none;
	resize: none;
	padding: 10px 0px 10px 15px;
	margin-top: 10px;
	/* border-radius: 20px; */
	height: 100px;
	font-size: 15px;
}

.form__input {
	margin-bottom: 10px;
	border: none;
	/* border-radius: 20px; */
	padding: 10px 0px 10px 15px;
}

.form__button {
	text-transform: uppercase;
	margin-top: 20px;
	padding: 10px 0;
	border: none;
	/* border-radius: 20px; */
	letter-spacing: 0.130em;
	font-weight: bold;
	background: #FFD700;
	border: 4px solid #16b0c8;
}

.form img {
	width: 22px;
	height: 22px;
}

.form__user {
	position: absolute;
	top: 105px;
	right: 48px;
}

.form__email {
	position: absolute;
	top: 150px;
	right: 48px;
}

/* Form end*/

.service-card__title {
	font-family: "PT Serif", Sans-serif;
	font-size: 26px;
	font-weight: 600;

}

.service-card__subtitle {
	font-family: "PT Serif", Sans-serif;
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 15px;
}

.service-card__days {
	margin-top: 25px;
	width: 350px;
}

.service-card__monday {
	margin-top: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	font-weight: 500;
}

.service-card__monday_right {
	color: #fae80c;
}


.info {
	background: url('/public/image/background-info.png') no-repeat top center / cover;
	padding-top: 130px;
	padding-bottom: 130px;
	position: relative;
}

.info-title {
	text-align: center;
	color: #fff;
	font-size: 40px;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 30px;
}

.info-text {
	text-align: center;
	color: #fff;
	font-size: 16px;
	margin-bottom: 50px;
}

.info-btn {
	display: block;
	margin: 0 auto;
	border-radius: 50px;
	width: 210px;
	background: none;
	color: #fff;
	line-height: 50px;
	border-color: #fff;
	text-transform: uppercase;
}


.review-slider {
	margin-top: 50px;
}

.review-slider__profile {
	cursor: pointer;
	position: relative;
}

.review-slider__image {
	width: 70px;
	height: 70px;
	position: absolute;
	left: 0;
}

.review-slider__text {
	color: #303030;
	letter-spacing: 0.022em;
	overflow-wrap: break-word;
	line-height: 1em;
}



.experience {
	padding-top: 60px;
	padding-bottom: 60px;
	font-family: 'Asap', sans-serif;
	background: #d6eef4;
}

.skill__digit {
	font-size: 50px;
	font-weight: 700;
	color: #00bcd1;
	margin-right: 10px;
}

.skill__text {
	text-transform: uppercase;
	color: #346bb0;
	width: 100px;
	font-weight: 500;
}

.skill__item {
	display: flex;
	align-items: center;
}

.skill__text_width {
	width: 60px;
}

/* Data */

.data {
	background: #f5fafd;
	padding-top: 40px;
	padding-bottom: 40px;
}

/* Form */

.js-form {
	width: 620px;
	background: #fff;
	text-align: center;
	border-radius: 10px;
	padding: 50px 50px;
	margin: 0 auto;
	border: 3px solid;
	border-color: rgba(22, 176, 200, 0.5);
}

.js-form input {
	width: 100%;
	border: none;
	border-bottom: 1px solid #757888;
	padding-top: 25px;
	padding-bottom: 20px;
	font-family: 'Asap', sans-serif;
	color: #42474c;
	font-size: 14px;
}

.js-form__title {
	font-size: 30px;
	font-family: 'PT Serif', serif;
	margin-bottom: 5px;
}

.js-form__submit {
	color: #fff;
	border: none;
	border-radius: 100px;
	background: #16b0c8;
	display: block;
	width: 400px;
	height: 60px;
	margin: 0 auto;
	margin-top: 40px;
	text-transform: uppercase;
	letter-spacing: 0.088em;
	cursor: pointer;
}

::-webkit-input-placeholder {
	color: #42474c;
}

::-ms-input-placeholder {
	color: #42474c;
}


::-moz-input-placeholder {
	color: #42474c;
}

/* Footer */

.footer {
	background: #2b3238;
	padding-top: 45px;
	padding-bottom: 40px;
	font-size: 15px;
}

.info-footer__title {
	font-family: 'PT Serif', serif;
	font-size: 25px;
	color: #fff;
	margin-bottom: 25px;
}

.info-footer__item {
	color: #fff;
	font-family: 'Roboto', sans-serif;
}

.info-footer__text {
	margin-bottom: 5px;
	font-weight: 300;
}

.info-footer__link {
	color: #2f8082;
	font-weight: 600;
	display: block;
	margin-bottom: 5px;
}

.info-footer__number {
	display: block;
	color: #eae048;
	font-weight: 600;
}

.footer-days {
	margin-top: 15px;
}

.info-footer__indent {
	margin-top: 20px;
}

.info-footer__indent .info-footer__text {
	font-weight: 400;
}

.info-footer__text_indent {
	margin-bottom: 10px;
	color: #fff;
	display: block;
}

.footer {
	position: relative;
}

.info-footer__line {
	width: 100%;
	height: 1px;
	background: #b3b3b3;
	position: absolute;
	top: 20px;
	left: 0;
	opacity: 0.3;
}

.info-footer__line_bottom {
	bottom: 20px;
	background: #b3b3b3;
	height: 1px;
	width: 100%;
	height: 1px;
	background: #b3b3b3;
	position: absolute;
	left: 0;
	opacity: 0.3;
}

.scrollup {
	width: 40px;
	height: 40px;
	opacity: 0.6;
	position: fixed;
	bottom: 50px;
	right: 100px;
	display: none;
	text-indent: -9999px;
	background: url('/public/image/icon_top.png') no-repeat;
}

#page-preloader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000;
	z-index: 100500;
}

.cssload-container {
	display: block;
	margin: 0 auto;
	margin-top: 350px;
	width: 97px;
}

.cssload-loading {
	width: 29px;
	height: 29px;
}

.cssload-loading i {
	width: 29px;
	height: 29px;
	display: inline-block;
	background: rgb(255, 255, 255);
	border-radius: 50%;
	position: absolute;
}

.cssload-loading i:nth-child(1) {
	transform: translate(-49px, 0);
	-o-transform: translate(-49px, 0);
	-ms-transform: translate(-49px, 0);
	-webkit-transform: translate(-49px, 0);
	-moz-transform: translate(-49px, 0);
	animation: cssload-loading-ani1 1.15s linear infinite;
	-o-animation: cssload-loading-ani1 1.15s linear infinite;
	-ms-animation: cssload-loading-ani1 1.15s linear infinite;
	-webkit-animation: cssload-loading-ani1 1.15s linear infinite;
	-moz-animation: cssload-loading-ani1 1.15s linear infinite;

}

.cssload-loading i:nth-child(2) {
	background: rgb(0, 168, 206);
	transform: translate(49px, 0);
	-o-transform: translate(49px, 0);
	-ms-transform: translate(49px, 0);
	-webkit-transform: translate(49px, 0);
	-moz-transform: translate(49px, 0);
	animation: cssload-loading-ani2 1.15s linear infinite;
	-o-animation: cssload-loading-ani2 1.15s linear infinite;
	-ms-animation: cssload-loading-ani2 1.15s linear infinite;
	-webkit-animation: cssload-loading-ani2 1.15s linear infinite;
	-moz-animation: cssload-loading-ani2 1.15s linear infinite;
}



@keyframes cssload-loading-ani1 {
	25% {
		z-index: 2;
	}

	50% {
		transform: translate(49px, 0) scale(1);
	}

	75% {
		transform: translate(0, 0) scale(0.75);
	}

	100% {
		transform: translate(-49px, 0) scale(1);

	}
}

@-o-keyframes cssload-loading-ani1 {
	25% {
		z-index: 2;
	}

	50% {
		-o-transform: translate(49px, 0) scale(1);
	}

	75% {
		-o-transform: translate(0, 0) scale(0.75);
	}

	100% {
		-o-transform: translate(-49px, 0) scale(1);

	}
}

@-ms-keyframes cssload-loading-ani1 {
	25% {
		z-index: 2;
	}

	50% {
		-ms-transform: translate(49px, 0) scale(1);
	}

	75% {
		-ms-transform: translate(0, 0) scale(0.75);
	}

	100% {
		-ms-transform: translate(-49px, 0) scale(1);

	}
}

@-webkit-keyframes cssload-loading-ani1 {
	25% {
		z-index: 2;
	}

	50% {
		-webkit-transform: translate(49px, 0) scale(1);
	}

	75% {
		-webkit-transform: translate(0, 0) scale(0.75);
	}

	100% {
		-webkit-transform: translate(-49px, 0) scale(1);

	}
}

@-moz-keyframes cssload-loading-ani1 {
	25% {
		z-index: 2;
	}

	50% {
		-moz-transform: translate(49px, 0) scale(1);
	}

	75% {
		-moz-transform: translate(0, 0) scale(0.75);
	}

	100% {
		-moz-transform: translate(-49px, 0) scale(1);

	}
}

@keyframes cssload-loading-ani2 {
	25% {
		transform: translate(0, 0) scale(0.75);
	}

	50% {
		transform: translate(-49px, 0) scale(1);
	}

	75% {
		z-index: 2;
	}

	100% {
		transform: translate(49px, 0) scale(1);
	}
}

@-o-keyframes cssload-loading-ani2 {
	25% {
		-o-transform: translate(0, 0) scale(0.75);
	}

	50% {
		-o-transform: translate(-49px, 0) scale(1);
	}

	75% {
		z-index: 2;
	}

	100% {
		-o-transform: translate(49px, 0) scale(1);
	}
}

@-ms-keyframes cssload-loading-ani2 {
	25% {
		-ms-transform: translate(0, 0) scale(0.75);
	}

	50% {
		-ms-transform: translate(-49px, 0) scale(1);
	}

	75% {
		z-index: 2;
	}

	100% {
		-ms-transform: translate(49px, 0) scale(1);
	}
}

@-webkit-keyframes cssload-loading-ani2 {
	25% {
		-webkit-transform: translate(0, 0) scale(0.75);
	}

	50% {
		-webkit-transform: translate(-49px, 0) scale(1);
	}

	75% {
		z-index: 2;
	}

	100% {
		-webkit-transform: translate(49px, 0) scale(1);
	}
}

@-moz-keyframes cssload-loading-ani2 {
	25% {
		-moz-transform: translate(0, 0) scale(0.75);
	}

	50% {
		-moz-transform: translate(-49px, 0) scale(1);
	}

	75% {
		z-index: 2;
	}

	100% {
		-moz-transform: translate(49px, 0) scale(1);
	}
}

.box-modal {
	position: relative;
	width: 85%;
	padding: 16px;
	background: #fff;
	color: #3c3c3c;
	font: 14px/18px Arial, "Helvetica CY", "Nimbus Sans L", sans-serif;
	box-shadow: 0 0 0 6px rgba(153, 153, 153, .3);
	border-radius: 6px;
}

.box-modal_close {
	position: absolute;
	right: 10px;
	top: 6px;
	font-size: 11px;
	line-height: 15px;
	color: #999;
	cursor: pointer;
}

.box-modal_close:hover {
	color: #666;
}

.container-map {
	width: 100%;
}

.info-footer__wrap {
	display: flex;
}

.info-footer__item_last {
	margin-left: 290px;
}

.menu-open {
	display: none;
}

.menu-open {
	background: none;
	border: 0;
	color: #000;
	font-size: 25px;
	cursor: pointer;
}

.menu-opened {
	background: rgba(28, 55, 76, 0.87);
	border-radius: 10px;
	padding: 20px;
	flex-direction: column;
	position: absolute;
	width: 65%;
	z-index: 9999;
	box-shadow: 0 0 40px #00000073;
}

.menu.menu-opened .menu__item {
	margin-right: 0px;
	margin-bottom: 10px;
}

.menu.menu-opened .menu__item a {
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	padding: 15px 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.6);
	display: block;
}

.top-info__social a {
	margin-right: 10px;
}


#loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(240, 240, 240, 0.91);
	overflow: hidden;
	display: none;
}

label.error {
	font-size: 12px;
	color: red;
	position: absolute;
}

input.error {
	border: 1px solid red !important;
}

input.valid {
	border: 1px solid green !important;
}

#loader img {
	position: absolute;
	width: 50px;
	height: 50px;
	left: 50%;
	margin-left: -25px;
	top: 50%;
	margin-top: -25px;
}

.data {
	position: relative;
}

#overlay {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #000c;
	left: 0px;
	top: 0px;
	cursor: pointer;
}

#thx {
	background: #16b0c8;
	position: absolute;
	left: 50%;
	margin-left: -170px;
	color: #fff;
	border-radius: 5px;
	top: 50%;
	padding: 30px;
	margin-top: -20px;
}

.slick-prev,
.slick-next {
	font-size: 42px;
	color: #16b0c8;
	width: 21px;
	height: 28px;
	margin-top: -14px;
	transition: all 0.5s ease;
}

.slick-prev {
	left: -53px;
}

.car-slider .slick-prev {
	font-size: 20px;
}

.slick-prev:hover {
	color: #4404cc !important;
	left: -59px;
}

.slick-next {
	right: -53px;
}

.car-slider .slick-next {
	font-size: 20px;
}

.slick-next:hover {
	color: #4404cc !important;
	right: -59px;
}

.slick-prev:before {
	display: none;
}

.slick-next:before {
	display: none;
}

.slick-dots {
	bottom: -45px;
}

.slick-dots li button::before {
	font-family: slick;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 16px;
	height: 16px;
	content: "";
	opacity: 1;
	background: #16b0c8;
	border-radius: 50%;
}

.slick-dots li.slick-active button::before {
	background: #16b0c8;
}

.slick-slide {
	opacity: 0.5;
}

.slick-current {
	opacity: 1;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}


.btn:hover {
	box-shadow: 0 0 10px 0 #f6fcf8 inset, 0 0 10px 4px #00090f;
}