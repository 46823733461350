.questions {
  background: #effafb;
  font-family: 'Asap', sans-serif;
  padding-top: 50px;
  padding-bottom: 80px;
  position: relative;
}

.question-title {
  font-size: 22px;
  font-weight: 600;
  color: #696d33;
  margin-bottom: 8px;
  letter-spacing: 0.025em;
  text-align: center;
}

.question-slider__text {
  color: #303030;
  letter-spacing: 0.022em;
  line-height: 1.7em;
}

.question-slider__name {
  font-size: 17px;
  font-weight: 600;
  color: #3c3c3c;
  margin-top: 10px;
}

.question-slider__name span {
  display: block;
  font-size: 12px;
  font-weight: 300;
  margin-left: 22px;
  margin-top: 5px;
}


.question-slider {
  margin-top: 20px;
  height: 333px;
  border-style: ridge;
  overflow-y: scroll;
  padding-left: 10px;
  box-shadow: aqua;
  border-radius: 15px;
}

.question-slider::-webkit-scrollbar {
  width: 20px;
  height: 1px;
}

.question-slider::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #48bbcf;
  border: 4px solid #d6eef4;
}

.question-slider::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f1f5f4;
}

.question-slider__profile {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  border-bottom-style: inset;
}

.question-slider__profile-item {
  margin: 10px 0;
}

.question-slider__profile-item:first-child {
  min-width: 60%;
}

.question-slider__profile-item:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: end;
}

.question-slider__profile-item:last-child button {
  margin-bottom: 3px;
  color: #fff;
  border: none;
  background: #16b0c8;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px 15px;
}

.question-slider__title {
  font-size: 17px;
  font-weight: bold;
}

.question-slider__text {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}

.question-button {
  color: #fff;
  border: none;
  /* border-radius: 100px; */
  background: #16b0c8;
  display: block;
  width: 300px;
  height: 60px;
  margin: 15px auto 0 auto;
  text-transform: uppercase;
  letter-spacing: 0.088em;
  cursor: pointer;
}

.css-1chwz6k-MuiPaper-root-MuiDialog-paper {
  background: #09555f !important;
  color: white !important;
}

.MuiDialogContent-root {
  padding-bottom: 11px !important;
}

.post-title {
  font-size: 20px;
  font-weight: 400;
}

.post-detail__wrapper {
  margin-top: 20px;
}

.post-detail__inner {
  margin-bottom: 15px;
}

.post-detail__user-name {
  color: #79acb3;
}

.post-detail__title {
  font-size: 17px;
}